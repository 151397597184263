import { Component, DebugElement, OnInit, ViewChild } from '@angular/core';
import { GraphService } from 'app/services/graph.service';
import { Inject } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { combineLatest } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

Chart.register(...registerables);

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit {

  // Arrays que guardan los índices de los valores seleccionados
  mySelectedVino: FormArray
  mySelectedClima: FormArray

  cargarDatosForm!: FormGroup

  // Variable para controlar la carga de los datos
  cargando: any

  erroresForm: any = {

    'fecha_ini': '',
    'fecha_fin': '',
    'param_vino': '',
    'param_clima': ''

  };

  mensajesError: any = {

    'fecha_ini': {
      'required': 'La fecha inicial es obligatoria.',
    },
    'fecha_fin': {
      'required': 'La fecha final es obligatoria.',
    },
    'param_vino': '',
    'param_clima': '',
  };

  param_vino_values = ["pH", "Acidez Total", "Grado Alcohólico Adquirido", "Azúcares reductores", "Sulfuroso Libre SO2 L"]
  param_vino_lables = ["pH", "Acidez Total (g/L ácido tartárico)", "Grado Alcohólico Adquirido (% Vol)", "Azúcares reductores (g/L)", "Sulfuroso Libre SO2 L (mg/L)"]
  param_clima_values = ["temperatura_media", "temperatura_media_max", "temperatura_media_min", "precipitacion_total", "dias_lluvia", "num_muestras"]
  param_clima_lables = ["Temperatura media (ºC)", "Temperatura media máxima (ºC)", "Temperatura media mínima (ºC)", "Precipitación total (mm)", "Días de lluvia", "Número de muestras"]
  param_vino_colors = ["#2ECC71", "#A569BD", "#F1C40F", "#FF00FF", "#117A65"]
  param_clima_colors = ["#FF0000", "#CB4335", "#EC7063", "#2471A3", "#00FFFF", "#FF6600"]


  data_clima: any
  data_vino: any
  
  chart_vino: any = []
  chart_data_vino: any
  chart_clima: any = []
  chart_data_clima: any

  // Fechas mínima y máxima de los datos
  min_fecha = {year: 2012, month: 1, day: 1}
  max_fecha = {year: 2020, month: 12, day:31}

  // Definiciónd de datos iniciales
  fecha_ini = "2020-01-01"
  fecha_fin = "2020-06-30"
  param_vino = "pH"
  param_clima = "temperatura_media"

  selected_vino = 0
  selected_clima = 0
  selected_fecha_ini = "2020-01-01"
  selected_fecha_fin = "2020-06-30"

  filterClass = "filter_rain"

  constructor(private graphService: GraphService, @Inject('baseURL') public BaseURL: string, private fb: FormBuilder) {
    this.crearFormulario()
  }

  ngOnInit(): void {
    this.crearChart()
  }

  plotData() {
   
    this.removeData(this.chart_vino)
    this.removeData(this.chart_clima)

    this.setFilterClass()

    let result_vino_array = []
    let result_clima_array = []
    let result_vino_new
    let result_clima_new

    for (let i = 0; i < this.mySelectedVino.length; i++) {
      let selected = this.mySelectedVino.value[i]
      result_vino_new = this.graphService.parametroVino(this.param_vino_values[selected], this.fecha_ini, this.fecha_fin)
      let label_vino = this.param_vino_lables[selected]
      let data_vino

      result_vino_new.subscribe(result => {
        if (result['message'] == "Success") {
          result_vino_array.push(result['parametro_vino'])

          
          data_vino = result['parametro_vino'].map((data: any) => data[0])

          let color = this.param_vino_colors[selected]
      
          
          this.addData(this.chart_vino, this.chart_data_vino, this.mySelectedVino, label_vino, data_vino, color)
        }
      })
    }

    for (let i = 0; i < this.mySelectedClima.length; i++) {
      let selected = this.mySelectedClima.value[i]
      result_clima_new = this.graphService.parametroClima(this.param_clima_values[selected], this.fecha_ini, this.fecha_fin)
      let label_clima = this.param_clima_lables[selected]
      let data_clima
      let valor_fecha_ini

      // Añadimos los resultados a un array
      result_clima_new.subscribe(result => {
        if (result['message'] == "Success") {
          result_clima_array.push(result['parametro_clima'])
        
          if(this.param_clima_values[selected] == "num_muestras") {
            data_clima = result['parametro_vino'].map((data: any) => data['num_muestras'])
          } else
            data_clima = result['parametro_clima'].map((data: any) => data[this.param_clima_values[selected]])

          if(this.param_clima_values[selected] == "num_muestras")
            valor_fecha_ini = result['parametro_vino'].map((data: any) => data['clima_idclima__fecha_inicial'])
          else
            valor_fecha_ini = result['parametro_clima'].map((data: any) => data['fecha_inicial'])
          
          let  meses_anyo = valor_fecha_ini.map((fecha: any) => this.nombreMes(fecha) + " " + fecha.substring(0, 4));

          let color = this.param_clima_colors[selected]
          
          
          this.addData(this.chart_clima, this.chart_data_clima, this.mySelectedClima, label_clima, data_clima, color)
          this.addLabels(this.chart_clima, meses_anyo)
          this.addLabels(this.chart_vino, meses_anyo)

        }
      })
    }

  }
  
  crearChart() {
    this.cargando = true;

    let result_clima = this.graphService.parametroClima(this.param_clima, this.fecha_ini, this.fecha_fin)

    let result_vino = this.graphService.parametroVino(this.param_vino, this.fecha_ini, this.fecha_fin)

    this.setFilterClass()

    // https://www.querythreads.com/how-to-wait-for-two-observables-in-rx-js/
    combineLatest([result_clima, result_vino], (res_clima, res_vino) => ({ res_clima, res_vino }))
      .subscribe(pair => {
        this.data_clima = pair.res_clima;
        this.data_vino = pair.res_vino;


        let valor_clima = this.data_clima['parametro_clima'].map((data: any) => data[this.param_clima])
        let valor_vino = this.data_vino['parametro_vino'].map((data: any) => data[0]) // Por la forma en la que devuelve los datos el servidor se cogen los primeros valores de la respuesta y no los etiquetados con "param_vino", pues el nombre no aparece
        
        let valor_fecha_ini = this.data_clima['parametro_clima'].map((data: any) => data['fecha_inicial'])
        let valor_fecha_fin = this.data_clima['parametro_clima'].map((data: any) => data['fecha_final'])

        let meses_anyo = valor_fecha_ini.map((fecha: any) => this.nombreMes(fecha) + " " + fecha.substring(0, 4));
        
        // Set font color to white
        Chart.defaults.color = "#ffffff";
        Chart.defaults.borderColor	= "#ffffff99";

        let color1 = this.param_clima_colors[this.selected_clima]
        let color2 = this.param_vino_colors[this.selected_vino]

        this.chart_data_vino =
        {
          labels: meses_anyo,
          datasets: [
            {
              label: this.param_vino_lables[this.selected_vino],
              data: valor_vino,
              borderColor: color2,
              backgroundColor: color2,
              fill: false,
            },
          ]
        }

        this.chart_data_clima =
        {
          labels: meses_anyo,
          datasets: [
            {
              label: this.param_clima_lables[this.selected_clima],
              data: valor_clima,
              borderColor: color1,
              backgroundColor: color1,
              fill: false,
            }
          ]
        }

        
        this.chart_vino = new Chart('myCanvasIdVino', {
          type: 'line',
          data: this.chart_data_vino,
          options: {
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
          }
        })

        this.chart_clima = new Chart('myCanvasIdClima', {
          type: 'line',
          data: this.chart_data_clima,
          options: {
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
          }
        })

        this.cargando = false;
      })
  }

  crearFormulario() {
    this.cargarDatosForm = this.fb.group({
      fecha_ini: [this.fecha_ini, [Validators.required]],
      fecha_fin: [this.fecha_fin, [Validators.required]],
      param_vino: [this.param_vino],
      param_clima: [this.param_clima],
      mySelectedVino: new FormArray([]),
      mySelectedClima: new FormArray([])
    });

    this.cargarDatosForm.controls["fecha_ini"].setValue({ 'year': 2020, 'month': 1, 'day': 1 })
    this.cargarDatosForm.controls["fecha_fin"].setValue({ 'year': 2020, 'month': 6, 'day': 30 })
    this.cargarDatosForm.controls["param_vino"].setValue(this.selected_vino)
    this.cargarDatosForm.controls["param_clima"].setValue(this.selected_clima)

    this.cargarDatosForm.valueChanges.subscribe(datos => this.onCambioValor(datos));
    this.onCambioValor();

    this.mySelectedVino = this.cargarDatosForm.get('mySelectedVino') as FormArray;
    this.mySelectedVino.push(new FormControl(0))

    this.mySelectedClima = this.cargarDatosForm.get('mySelectedClima') as FormArray;
    this.mySelectedClima.push(new FormControl(0))

  }

  onCambioValor(data?: any) {
    if (!this.cargarDatosForm) {
      return;
    }
    const form = this.cargarDatosForm;
    for (const field in this.erroresForm) {
      // Se borrarán los mensajes de error previos
      this.erroresForm[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.mensajesError[field];
        for (const key in control.errors) {
          if (messages[key] != undefined)
            this.erroresForm[field] += messages[key];
        }
      }
    }

    this.selected_fecha_ini = this.cargarDatosForm.get("fecha_ini")?.value
    this.selected_fecha_fin = this.cargarDatosForm.get("fecha_fin")?.value
    this.selected_vino = this.cargarDatosForm.get("param_vino")?.value
    this.selected_clima = this.cargarDatosForm.get("param_clima")?.value

  }

  cargarDatos() {
    this.fecha_ini = this.parseDate(this.selected_fecha_ini)
    this.fecha_fin = this.parseDate(this.selected_fecha_fin)

    this.removeData(this.chart_vino)
    this.removeData(this.chart_clima)

    this.cargando = true
    this.plotData()
  }

  // https://stackoverflow.com/questions/34535811/angular-js-replace-month-number-with-month-name
  nombreMes(stringDate: string) {
    var month = stringDate.substring(5, 7);
    var returnMonth;

    switch (month) {
      case "01": returnMonth = "Enero"; break;
      case "02": returnMonth = "Febrero"; break;
      case "03": returnMonth = "Marzo"; break;
      case "04": returnMonth = "Abril"; break;
      case "05": returnMonth = "Mayo"; break;
      case "06": returnMonth = "Junio"; break;
      case "07": returnMonth = "Julio"; break;
      case "08": returnMonth = "Agosto"; break;
      case "09": returnMonth = "Septiembre"; break;
      case "10": returnMonth = "Octubre"; break;
      case "11": returnMonth = "Noviembre"; break;
      case "12": returnMonth = "Diciembre"; break;
    }

    return returnMonth;
  }

  parseDate(selected_date: any): string {
    let month = selected_date["month"].toString()
    if (month.length == 1)
      month = "0" + month

    return selected_date["year"] + "-" + month + "-" + selected_date["day"]
  }

  onCheckChangeVino(event) {
    const formArray: FormArray = this.cargarDatosForm.get('mySelectedVino') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }

    this.mySelectedVino = formArray
  }

  onCheckChangeClima(event) {
    const formArray: FormArray = this.cargarDatosForm.get('mySelectedClima') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }

    this.mySelectedClima = formArray
  }

  // https://www.chartjs.org/docs/latest/developers/updates.html
  addData(chart, chart_data, data_array, new_label, new_data, color_value) {
    var color = color_value
    var newDataset = {
      label: new_label,
      backgroundColor: color,
      borderColor: color,
      fill: false,
      data: new_data,
    }

    chart_data.datasets.push(newDataset);

    if((this.chart_data_vino.datasets.length+this.chart_data_clima.datasets.length) == (this.mySelectedClima.value.length+this.mySelectedVino.value.length))
      this.cargando = false

    chart.update();
  }

  addLabels(chart, labels) {
    chart.data.labels = labels;
    chart.update();
  }

  removeData(chart) {
    chart.data.datasets.splice(0,chart.data.datasets.length)

    chart.data.labels.splice(0,chart.data.labels.length)

    chart.update();
  }

  setFilterClass() {
    let climaArray = this.mySelectedClima.value
    let rain =false
    let temp =false

    climaArray.forEach(value => {
      if(value == 0 || value == 1 || value == 2) {
        temp = true}

      if(value == 3 || value == 4){ 
        rain = true
      }
   
    });

    if(rain) {
      this.filterClass="filter_rain"
    } 
    if(temp) {
      this.filterClass="filter_temp"
    } 
    if(rain && temp) {
      this.filterClass="filter"
    }
  }

}
