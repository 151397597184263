import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { baseURLContactos } from 'app/compartido/baseurl';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  constructor(private http: HttpClient) { }
  
  createContacto(asunto:string,correo:string,mensaje:string) {
    return this.http.get(baseURLContactos + `create/?asunto=${encodeURIComponent(asunto)}&correo=${encodeURIComponent(correo)}&mensaje=${encodeURIComponent(mensaje)}`)
  }
}
