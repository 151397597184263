import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Inject } from '@angular/core';
import { ContactoService } from 'app/services/contacto.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  contactoForm!: FormGroup

  erroresForm: any = {

    'asunto': '',
    'correo': '',
    'mensaje': '',

  };

  mensajesError: any = {

    'asunto': {
      'required': 'El asunto es obligatorio.',
    },
    'correo': {
      'required': 'El correo es obligatorio.',
    },
    'mensaje': {
      'required': 'El mensaje es obligatorio.',
    },
  };

  selected_asunto = ""
  selected_correo = ""
  selected_mensaje = ""

  constructor(private contactoService: ContactoService, @Inject('baseURLContactos') public BaseURLContactos: string, private fb: FormBuilder) {
    this.crearFormulario()
  }

  ngOnInit(): void {
  }

  crearFormulario() {
    this.contactoForm = this.fb.group({
      asunto: ["", [Validators.required]],
      correo: ["", [Validators.required]],
      mensaje: ["", [Validators.required]],
    });

    this.contactoForm.valueChanges.subscribe(datos => this.onCambioValor(datos));
    this.onCambioValor();
  }

  onCambioValor(data?: any) {
    if (!this.contactoForm) {
      return;
    }
    const form = this.contactoForm;
    for (const field in this.erroresForm) {
      // Se borrarán los mensajes de error previos
      this.erroresForm[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.mensajesError[field];
	console.log(control.errors);
        for (const key in control.errors) {
          if (messages[key] != undefined)
            this.erroresForm[field] += messages[key];
        }
      }
    }

    this.selected_asunto = this.contactoForm.get("asunto")?.value
    this.selected_correo = this.contactoForm.get("correo")?.value
    this.selected_mensaje = this.contactoForm.get("mensaje")?.value

  }

  onSubmit() {
    // console.log("Asunto: " + this.selected_asunto + ", Correo: " + this.selected_correo + ", Mensaje: " + this.selected_mensaje)
    this.contactoService.createContacto(this.selected_asunto, this.selected_correo, this.selected_mensaje).subscribe();
    this.contactoForm.reset()
  }

}
