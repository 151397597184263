import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { baseURL } from 'app/compartido/baseurl';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private http: HttpClient) { }

  parametroClima(param_clima:string,fecha_ini:string, fecha_fin:string) {
    if(param_clima == "num_muestras") {
      return this.http.get(baseURL + param_clima + "/" + fecha_ini + "/" + fecha_fin);
    } else  
      return this.http.get(baseURL + "clima/" +param_clima + "/" + fecha_ini + "/" + fecha_fin);

  }

  parametroVino(param_vino:string, fecha_ini:string, fecha_fin:string){
    
    return this.http.get(baseURL + "vino/" +param_vino + "/" + fecha_ini + "/" + fecha_fin);
    
  }


}
